import GoogleReview from '@/components/GoogleReview';
import { MetaHeader } from '@/components/MetaHeader';
import { Content } from '@/components/shared/Content';
import { TallyIframe } from '@/components/TallyIframe';
import { CheckIcon as ChakraCheckIcon, CloseIcon as ChakraCloseIcon } from '@chakra-ui/icons';
import { Button, Link } from '@chakra-ui/react';
import { MainLayout } from 'layouts/MainLayout';
import Image from 'next/image';
import { PropsWithChildren, useEffect, useState } from 'react';
import { BsCheckCircle } from 'react-icons/bs';

const carImagesDesktop = [
    '/images/car-logos/car-hero1-desktop.png',
    '/images/car-logos/car-hero2-desktop.png',
    '/images/car-logos/car-hero3-desktop.png',
    '/images/car-logos/car-hero4-desktop.png',
    '/images/car-logos/car-hero5-desktop.png',
    '/images/car-logos/car-hero6-desktop.png',
    '/images/car-logos/car-hero7-desktop.png',
    '/images/car-logos/car-hero8-desktop.png',
    '/images/car-logos/car-hero9-desktop.png',
    '/images/car-logos/car-hero10-desktop.png',
];

const carImagesTablet = [
    '/images/car-logos/car-hero1-tablet.png',
    '/images/car-logos/car-hero2-tablet.png',
    '/images/car-logos/car-hero3-tablet.png',
    '/images/car-logos/car-hero4-tablet.png',
    '/images/car-logos/car-hero5-tablet.png',
    '/images/car-logos/car-hero6-tablet.png',
    '/images/car-logos/car-hero7-tablet.png',
    '/images/car-logos/car-hero8-tablet.png',
    '/images/car-logos/car-hero9-tablet.png',
    '/images/car-logos/car-hero10-tablet.png',
];

const carImagesMobile = [
    '/images/car-logos/car-hero1-mobile.png',
    '/images/car-logos/car-hero2-mobile.png',
    '/images/car-logos/car-hero3-mobile.png',
    '/images/car-logos/car-hero4-mobile.png',
    '/images/car-logos/car-hero5-mobile.png',
    '/images/car-logos/car-hero6-mobile.png',
    '/images/car-logos/car-hero7-mobile.png',
    '/images/car-logos/car-hero8-mobile.png',
    '/images/car-logos/car-hero9-mobile.png',
    '/images/car-logos/car-hero10-mobile.png',
];

type ImageType = 'desktop' | 'tablet' | 'mobile';

function ImageSlider({ type }: { readonly type: ImageType }) {
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImage((currentImage) => (currentImage + 1) % carImagesDesktop.length);
        }, 1500);

        return () => clearInterval(intervalId);
    }, []);

    const imagesByType: Record<ImageType, string[]> = {
        desktop: carImagesDesktop,
        tablet: carImagesTablet,
        mobile: carImagesMobile,
    };

    const carImagesType = imagesByType[type][currentImage];

    return (
        <div className="flex w-full h-full justify-center items-center relative">
            <div className="z-0 md:absolute md:top-16 lg:top-12">
                <Image
                    src={'/images/isologos/clidrive-isologo-gradient-orange-cut.svg'}
                    alt="Logo clidrive naranja"
                    className="lg:w-[400px]"
                    width={300}
                    height={300}
                />
            </div>
            <div className="z-10 absolute top-20 md:top-32 lg:top-36 min-h-[180px] lg:min-h-[258px] flex items-end transition-opacity duration-1000 ease-in-out">
                <img src={carImagesType} alt="Slideshow" className="md:w-[350px] lg:w-[450px] xl:w-[500px]" />
            </div>
        </div>
    );
}

export default function Home() {
    const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
    const og_image = `${BASE_URL}/images/og/og-compra.jpg`;
    const description =
        'Compra en nuestro concesionario online y disfruta de hasta 30 días o 1,500 km de prueba. Recibe tu coche con entrega gratuita y garantía premium. Comprar un coche 100% online es posible y más fácil que nunca.';

    return (
        <MainLayout header="default">
            <MetaHeader og_image={og_image} description={description} />
            <section className="h-full bg-radial-gradient pt-4 md:min-h-[450px] lg:min-h-[500px] xl:min-h-[600px]">
                <Content className={'mx-auto h-full '} withoutPadding>
                    <div className="h-full relative md:grid md:grid-cols-2 md:gap-2 md:justify-around md:px-4">
                        <div className="flex justify-center md:hidden min-h-[250px]">
                            <ImageSlider type="mobile" />
                        </div>
                        <div className="flex justify-center md:mb-0 md:place-content-center px-[16px] py-7">
                            <div className="heroCTA flex md:flex-col md:rounded-bl-[100px]">
                                <div className={'flex flex-col gap-10 md:justify-center flex-1 text-black'}>
                                    <div className={'flex flex-col gap-4'}>
                                        <h1 className="text-white font-900 text-[2.375rem] md:text-[3.375rem] leading-[42px] md:leading-[50px] xl:leading-[64px]">
                                            Movilidad para todos
                                        </h1>
                                        <h2 className="text-white font-600 text-2xl md:text-3xl">
                                            Comprar, Vender o acceder a un Renting nunca fue tan fácil
                                        </h2>
                                        <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                                            <div className="col-span-2 md:col-span-1">
                                                <Link href="https://coches.clidrive.com/">
                                                    <Button
                                                        id="HM-CTA-OFFER-HERO"
                                                        bg={''}
                                                        className={
                                                            'bg-white h-[52px] text-azure-800 font-500 rounded-[8px] w-full shadow-lg'
                                                        }
                                                    >
                                                        {'Comprar'}
                                                    </Button>
                                                </Link>
                                            </div>
                                            <a href="/vende-tu-coche">
                                                <Button
                                                    id="HM-CTA-OFFER-HERO"
                                                    bg={''}
                                                    className={'bg-white h-[52px] text-azure-800 font-500 rounded-[8px] w-full shadow-lg'}
                                                >
                                                    {'Vender'}
                                                </Button>
                                            </a>
                                            <a href="https://www.coches.net/renting/concesionario/clidrivegrouprenting/" target="blank">
                                                <Button
                                                    id="HM-CTA-OFFER-HERO"
                                                    bg={''}
                                                    className={'bg-white h-[52px] text-azure-800 font-500 rounded-[8px] w-full shadow-lg'}
                                                >
                                                    {'Renting'}
                                                </Button>
                                            </a>
                                        </div>
                                        <p className="text-white font-600 text-2xl md:text-3x">Más de 1.000 coches disponibles</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hidden relative md:flex md:justify-center md:items-center xl:hidden">
                            <ImageSlider type="tablet" />
                        </div>
                        <div className="hidden relative xl:flex xl:justify-center xl:items-center">
                            <ImageSlider type="desktop" />
                        </div>
                    </div>
                </Content>
            </section>
            <section className="bg-lavender-100 px-4 py-7">
                <Content className={'mx-auto h-full '} withoutPadding>
                    <div className="flex flex-col items-center justify-center gap-2">
                        <Image src={'/images/isologos/isologo-orange.svg'} alt="Logo Clidrive" width={50} height={50} />
                        <h1 className="font-900 text-azure-800 text-4xl text-center">Descubre todos nuestros productos</h1>
                    </div>
                    <div className="md:grid md:grid-cols-3">
                        <div className="flex flex-col justify-between p-6">
                            <hr className="rounded-md border-y-2 border-azure-600 my-4 md:hidden" />
                            <div className="py-2">
                                <h1 className="font-900 text-azure-600 text-4xl text-center">Comprar</h1>
                                <div className="flex md:min-h-[200px] items-center justify-center p-4">
                                    <Image className="" src={'/images/car-logos/cars.svg'} alt="Dos coches" width={350} height={250} />
                                </div>
                                <div className="flex flex-col gap-4">
                                    <div>
                                        <p className="text-center text-azure-800 text-xl font-bold ">
                                            Encuentra tu próximo coche certificado al mejor precio
                                        </p>
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <div className="flex items-center">
                                            <BsCheckCircle className="text-azure-600 w-12" />
                                            <p className=" text-azure-800 text-left w-[330px]">
                                                Amplia selección de vehículos certificados y listos para ti.
                                            </p>
                                        </div>
                                        <div className="flex items-center">
                                            <BsCheckCircle className="text-azure-600 w-12" />
                                            <p className=" text-azure-800 text-left w-[330px]">
                                                Contamos con el período de prueba más largo del mercado: 30 días o 1,500 km.
                                            </p>
                                        </div>
                                        <div className="flex items-center">
                                            <BsCheckCircle className="text-azure-600 w-12" />
                                            <p className=" text-azure-800 text-left w-[330px]">
                                                Opciones de financiación disponibles, cubriendo hasta 100% del valor del vehículo y tasas
                                                del 5,99% en adelante.
                                            </p>
                                        </div>
                                        <div className="flex items-center">
                                            <BsCheckCircle className="text-azure-600 w-12" />
                                            <p className=" text-azure-800 text-left w-[330px]">
                                                Garantía premium que protege tu coche hasta 2 años.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-4 items-center">
                                <p className="text-center text-azure-800 font-semibold">
                                    ¡Si encuentras un coche más barato te pagamos la diferencia!
                                </p>
                                <div className="col-span-2">
                                    <Link href="https://coches.clidrive.com/">
                                        <Button
                                            id="HM-CTA-OFFER"
                                            variant={''}
                                            bg={''}
                                            className={
                                                'bg-azure-600 text-white font-500 rounded-[8px] w-[220px] shadow-lg drop-shadow-xl hover:bg-azure-600/80'
                                            }
                                        >
                                            {'Comprar'}
                                        </Button>
                                    </Link>
                                </div>
                                <div className="md:h-[48px] min-[838px]:h-auto">
                                    <p className="text-center text-azure-800 font-semibold italic">¡DESDE 199€ AL MES! </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between p-6">
                            <hr className="rounded-md border-y-2 border-azure-600 my-4 md:hidden" />
                            <div className="py-2">
                                <h1 className="font-900 text-azure-600 text-4xl text-center">Vender</h1>
                                <div className="flex md:min-h-[200px] items-center justify-center p-4">
                                    <Image
                                        className=""
                                        src={'/images/car-logos/car-hero7-mobile_reverse.svg'}
                                        alt="Coche"
                                        width={250}
                                        height={250}
                                    />
                                </div>
                                <div className="flex flex-col gap-4">
                                    <div>
                                        <p className="text-center text-azure-800 text-xl font-bold">
                                            Vende tu coche y olvídate de futuras complicaciones
                                        </p>
                                    </div>
                                    <div className=" flex flex-col gap-4">
                                        <div className="flex items-center">
                                            <BsCheckCircle className="text-azure-600 w-12" />
                                            <p className=" text-azure-800 text-left w-[330px]">
                                                Envíanos unas fotos de tu coche y nosotros nos encargamos del resto.
                                            </p>
                                        </div>
                                        <div className="flex items-center">
                                            <BsCheckCircle className="text-azure-600 w-12" />
                                            <p className=" text-azure-800 text-left w-[330px]">
                                                Sigue disfrutando de tu coche mientras encontramos al mejor comprador para ti.
                                            </p>
                                        </div>
                                        <div className="flex items-center">
                                            <BsCheckCircle className="text-azure-600 w-12" />
                                            <p className=" text-azure-800 text-left w-[330px]">
                                                Te aseguramos el mejor precio posible con total transparencia.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-4 items-center">
                                <div className="col-span-2">
                                    <a href="/vende-tu-coche">
                                        <Button
                                            id="HM-CTA-OFFER"
                                            variant={''}
                                            bg={''}
                                            className={
                                                'bg-azure-600 text-white font-500 rounded-[8px] w-[220px] shadow-lg drop-shadow-xl hover:bg-azure-600/80'
                                            }
                                        >
                                            {'Vender'}
                                        </Button>
                                    </a>
                                </div>
                                <p className="text-center text-azure-800 font-semibold italic">Sin compromiso ni comisiones</p>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between p-6">
                            <hr className="rounded-md border-y-2 border-azure-600 my-4 md:hidden" />
                            <div className="py-2">
                                <h1 className="font-900 text-azure-600 text-4xl text-center">Renting</h1>
                                <div className="flex md:min-h-[200px] items-center justify-center p-4 ">
                                    <Image src={'/images/car-logos/car-renting.svg'} alt="Coche" width={250} height={250} />
                                </div>
                                <div className="flex flex-col gap-4">
                                    <div className="md:min-h-[56px]">
                                        <p className="text-center text-azure-800 text-xl font-bold">Las cuotas de alquiler más bajas</p>
                                    </div>
                                    <div className=" flex flex-col gap-4">
                                        <div className="flex items-center">
                                            <BsCheckCircle className="text-azure-600 w-12" />
                                            <p className=" text-azure-800 text-left w-[330px]">El Renting más accesible del mercado.</p>
                                        </div>
                                        <div className="flex items-center">
                                            <BsCheckCircle className="text-azure-600 w-12" />
                                            <p className=" text-azure-800 text-left w-[330px]">
                                                Un coche sin el compromiso de una compra y con la libertad de una mensualidad asequible.
                                            </p>
                                        </div>
                                        <div className="flex items-center">
                                            <BsCheckCircle className="text-azure-600 w-12" />
                                            <p className=" text-azure-800 text-left w-[330px]">
                                                Disfruta de todo incluido en tu cuota, con la máxima flexibilidad para adaptarse a tus
                                                necesidades.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-4 items-center">
                                <p className="text-center text-azure-800 font-semibold">¡Tenemos la solución perfecta para ti!</p>
                                <div className="col-span-2">
                                    <a href="https://www.coches.net/renting/concesionario/clidrivegrouprenting/" target="blank">
                                        <Button
                                            id="HM-CTA-OFFER"
                                            bg={''}
                                            variant={''}
                                            className={
                                                'bg-azure-600 text-white font-500 rounded-[8px] w-[220px] shadow-lg drop-shadow-xl hover:bg-azure-600/80'
                                            }
                                        >
                                            {'Renting'}
                                        </Button>
                                    </a>
                                </div>
                                <div className="md:h-[48px] min-[838px]:h-auto">
                                    <p className="text-center text-azure-800 font-semibold italic">¡DESDE 170€ AL MES!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Content>
            </section>
            <section className="bg-lavender-400 py-8">
                <Content className={'mx-auto h-full '} withoutPadding>
                    <div className="flex flex-col items-center justify-center gap-4">
                        <Image className="" src={'/images/clidrive-logo-azure-600.svg'} alt="Coche" width={250} height={250} />
                        <h1 className="font-extrabold italic text-3xl text-azure-600">Líderes en movilidad</h1>
                        <p className="text-center text-azure-800 text-[22px] font-bold"> Con más de 2.500 clientes satisfechos</p>
                        <div className="flex flex-col md:flex-row gap-8">
                            <GoogleReview stars={5} date="Julio 2023" name="" avatarSrc="/images/new-landing/google-reviews-avatar-1.svg">
                                <p>
                                    A pesar de mis circunstancias <span className="font-700">hicieron que todo fuese mucho más fácil</span>.
                                    Agradecer en todo momento a María su trato hacia mi persona.
                                </p>
                            </GoogleReview>
                            <GoogleReview
                                stars={5}
                                date="Septiembre 2024"
                                name=""
                                avatarSrc="/images/new-landing/google-reviews-avatar-3.svg"
                            >
                                <p>
                                    He anunciado mi vehículo con ellos y <span className="font-700">todo ha sido rápido y sencillo.</span> Y
                                    encima me quito de líos de tener que enseñar el vehículo, etc. Cuando venda mi coche de nuevo, volveré a
                                    contar con ellos, aunque espero que sea dentro de un tiempo.{' '}
                                    <span className="font-700">Gracias Clidrive.</span>
                                </p>
                            </GoogleReview>
                            <GoogleReview stars={5} date="Octubre 2024" name="" avatarSrc="/images/new-landing/google-reviews-avatar-2.svg">
                                <p>
                                    <span className="font-700">Todo muy bien,</span> me dieron una fecha de entrega y les pedí por favor si
                                    me lo podrían dar antes y así fue, <span className="font-700">muy flexibles y serviciales.</span>
                                </p>
                            </GoogleReview>
                        </div>
                    </div>
                </Content>
            </section>

            <section className="bg-lavender-100 py-7 px-4">
                <Content className={'mx-auto h-full '} withoutPadding>
                    <div>
                        <div className="flex flex-col gap-2">
                            <h1 className="font-900 text-azure-800 text-4xl text-center">¿Tienes dudas?</h1>
                            <p className="font-semibold text-azure-800 text-xl text-center">Te respondemos en menos de 24 horas</p>
                        </div>
                        <TallyIframe>
                            <iframe
                                data-tally-src="https://tally.so/embed/w2xAQA?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
                                loading="lazy"
                                width="100%"
                                height="626"
                                title="¿Tienes dudas?"
                            />
                        </TallyIframe>
                    </div>
                </Content>
            </section>
        </MainLayout>
    );
}

export function TableRow({
    children,
    className,
}: PropsWithChildren<{
    className?: string;
}>) {
    return <tr className={`md:h-10 ${className}`}>{children}</tr>;
}

export function CloseIcon({
    children,
    className,
}: PropsWithChildren<{
    className?: string;
}>) {
    return (
        <ChakraCloseIcon className={`border-2 rounded-full border-red-500 p-0.5 ${className}`} color={'red.500'}>
            {children}
        </ChakraCloseIcon>
    );
}

export function CheckIcon({
    children,
    className,
}: PropsWithChildren<{
    className?: string;
}>) {
    return (
        <ChakraCheckIcon className={`border-2 rounded-full border-azure-600 p-0.5 ${className}`} color={'#0081A8'}>
            {children}
        </ChakraCheckIcon>
    );
}
