import Image from 'next/image';
import { BsFillStarFill } from 'react-icons/bs';

export default function GoogleReview({
    children,
    stars,
    date,
    name,
    avatarSrc,
}: {
    readonly children?: React.ReactNode;
    stars: number;
    date: string;
    name: string;
    avatarSrc: string;
}) {
    return (
        <div className="bg-white rounded-xl p-4 max-w-[350px] space-y-4">
            <div className="flex gap-4">
                <Image src={avatarSrc} alt="" width={50} height={50} />
                <div className="flex flex-col">
                    <span className={'font-bold'}>{name}</span>
                    <div className="flex gap-1">
                        {[...Array(stars)].map((_, index) => (
                            <BsFillStarFill key={index} color="#FBBC04" />
                        ))}
                    </div>
                    <span className={'text-sm'}>{date}</span>
                </div>
            </div>
            {children}
        </div>
    );
}
