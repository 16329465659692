import Script from 'next/script';
import { useEffect } from 'react';

export function TallyIframe({ children }: { children?: React.ReactNode }) {
    const loadTallyEmbeds = () => {
        const Tally = (window as any).Tally;
        if (Tally) {
            Tally.loadEmbeds();
        }
    };

    useEffect(() => {
        loadTallyEmbeds();
    }, []);

    return (
        <>
            {children}
            <Script id="tally-js" src="https://tally.so/widgets/embed.js" onLoad={loadTallyEmbeds} />
        </>
    );
}
