import Head from 'next/head';
import { useRouter } from 'next/router';

export function MetaHeader({ og_image, description }: { readonly og_image: string; readonly description: string }) {
    const router = useRouter();
    const title = 'Clidrive | Líderes en Movilidad.';

    return (
        <Head>
            <link rel="canonical" href="https://www.clidrive.com/" />
            <meta lang="es" />
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={description} />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
            <meta charSet="UTF-8" />
            <meta content="index, follow" name="robots" />

            <meta property="og:image" content={og_image} />
            <meta property="og:image:secure_url" content={og_image} />
            <meta property="og:image:width" content="700" />
            <meta property="og:image:height" content="348" />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:alt" content={title} />
            <meta property="og:site_name" content="Clidrive" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={`https://www.clidrive.com${router.asPath}`} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="es_ES" />
            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:domain" content="clidrive.com" />
            <meta property="twitter:url" content={`https://www.clidrive.com${router.asPath}`} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={og_image} />
        </Head>
    );
}
